import { useContext } from 'react';
import { useAuth } from '@clerk/nextjs';
import { useClearCache } from 'core-api';
import * as Sentry from '@sentry/nextjs';
import { useSetAtom } from 'jotai';
import { useFlagsmith } from 'flagsmith/react';

import { analytics } from '/services/analytics';
import { clerkSigninTicketAtom } from '/contexts/clerkSigninTicket';
import { investLedgerIdAtom } from '/contexts/invest';
import { ApolloActionsContext } from '/contexts/ApolloProvider';

export const useLogout = () => {
  const setClerkSigninTicket = useSetAtom(clerkSigninTicketAtom);
  const setSelectedLedgerId = useSetAtom(investLedgerIdAtom);
  const { signOut } = useAuth();
  const flagsmith = useFlagsmith();
  const clearCache = useClearCache();
  const { clearCache: clearApolloCache } = useContext(ApolloActionsContext);

  return async () => {
    setClerkSigninTicket(undefined);
    setSelectedLedgerId(null);
    clearCache();
    await clearApolloCache();
    await signOut();
    Sentry.setUser(null);
    window.Intercom?.('shutdown');
    await flagsmith.logout();
    await analytics.reset();
    // 🚨 Eveything called after analytics.reset wont be called
  };
};
